<template>
  <b-navbar fixed-top shadow style="max-width: 100vw">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
            src="../assets/logo-nav.png"
            alt="Hirati: Ateneo Law School Career Fair 2023"
        >
      </b-navbar-item>
    </template>

    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/private-firms' }">
        Private Firms
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/list/ngo' }">
        NGOs
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/list/gov' }">
        Government
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/list/all' }">
        All Firms
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="router-link" :to="'/directory'">
        Directory
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="'/about'">
        About
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="'/faqs'">
        FAQs
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="'/contact'">
        Contact
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "NavCustom"
}
</script>