<template>
  <footer class="footer has-background-ghost-white">
    <div class="content has-text-centered">
      <img class="img" src="../assets/logo-footer.png">
      <p class="is-size-7 has-text-grey">HIRATI: Ateneo Law School Career Fair 2023</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterCustom"
}
</script>

<style scoped>
.img {
  max-height: 128px;
}
</style>