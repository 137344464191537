import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/list/:select',
    name: 'List',
    component: () => import(/* webpackChunkName: "all-employers" */ '../views/AllEmployers.vue')
  },
  {
    path: '/private-firms',
    name: 'PrivateFirms',
    component: () => import(/* webpackChunkName: "all-employers" */ '../views/PrivateFirms.vue')
  },
  {
    path: '/view/:id',
    name: 'Employer',
    component: () => import(/* webpackChunkName: "view-employer" */ '../views/EmployerView.vue')
  },
  {
    path: '/directory',
    name: 'Directory',
    component: () => import(/* webpackChunkName: "directory" */ '../views/Directory.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
